<template>
  <div>
    <v-row>
      <v-col class="px-0 py-0" cols="12">
        <FirewallFilter
          :is-loading="loading"
          :selected-ids="monitorAuditorRulesetFirewalls"
          @onFirewallsChange="onFirewallsChange($event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(severity, key) in data"
        :key="severity.id"
        class="px-0 py-0"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card
          class="synapsa-column"
          :class="[
            key === 3 ? 'last' : 'not-last',
            treeLikeArrow(severity.id),
            borderRadiusFix(severity),
          ]"
        >
          <v-card-title class="font-size-12 font-roboto-condensed-bold py-1">
            <v-icon :class="severityClass(severity.id)" small class="mr-2">
              mdi-view-grid
            </v-icon>
            {{ severity.name }}
            <div>
              <div
                v-if="severity.id === 1 && rulesetLowAlertsCount > 0"
                class="alert-count ml-2 py-1"
              >
                <span>{{ rulesetLowAlertsCount }}</span>
              </div>
              <div
                v-if="severity.id === 2 && rulesetMediumAlertsCount > 0"
                class="alert-count ml-2 py-1"
              >
                <span>{{ rulesetMediumAlertsCount }}</span>
              </div>
              <div
                v-if="severity.id === 3 && rulesetHighAlertsCount > 0"
                class="alert-count ml-2 py-1"
              >
                <span>{{ rulesetHighAlertsCount }}</span>
              </div>
              <div
                v-if="severity.id === 4 && rulesetCriticalAlertsCount > 0"
                class="alert-count ml-2 py-1"
              >
                <span>{{ rulesetCriticalAlertsCount }}</span>
              </div>
            </div>
          </v-card-title>
          <transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="d-none"
          >
            <v-card-text
              v-if="!loading"
              class="px-0 pb-0"
              style="height: calc(100% - 40px)"
            >
              <v-expansion-panels multiple v-model="expanded[key]">
                <v-expansion-panel
                  v-for="(auditor, index) in severity.auditors"
                  :key="auditor.id"
                  :class="[
                    index === severity.auditors.length - 1 ? 'last' : '',
                    (index + 1) % 2 === 0 ? 'even' : 'odd',
                  ]"
                  v-show="auditor.totalAlert > 0"
                >
                  <v-expansion-panel-header
                    class="py-0 font-size-12"
                    disable-icon-rotate
                  >
                    {{ auditor.name }}
                    <div>
                      <div
                        v-if="auditor.totalAlert > 0"
                        class="alert-right ml-2 py-1"
                      >
                        <span>{{ auditor.totalAlert }}</span>
                      </div>
                    </div>
                    <template v-slot:actions>
                      <v-avatar v-if="!isOpen(key, index)" size="25">
                        <img
                          :src="
                            $vuetify.theme.dark
                              ? location +
                                '/images/icons/white/folder-white.png'
                              : location + '/images/icons/dark/folder.png'
                          "
                          alt="Add"
                        />
                      </v-avatar>
                      <v-avatar v-else size="18">
                        <img
                          :src="
                            $vuetify.theme.dark
                              ? location + '/images/icons/white/close-white.svg'
                              : location + '/images/icons/dark/close.svg'
                          "
                          alt="Add"
                        />
                      </v-avatar>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                        <template
                          v-if="auditor.ruleset_mode !== 2"
                        >
                          <tr
                            v-for="(fw, fw_key) in auditor.fws"
                            :key="fw_key"
                            class="cursor-pointer"
                            @click="openForm(auditor, fw)"
                            v-show="fw.count_not_confirmed > 0"
                          >
                            <td class="py-0">{{ fw.name_fw }}</td>
                            <td class="py-0 text-right">
                              {{ fw.count_not_confirmed }}
                            </td>
                          </tr>
                        </template>
                        <template  v-else>
                          <tr
                            v-for="(fw, fw_key) in auditor.fws"
                            :key="fw_key"
                            v-show="fw.count_not_confirmed > 0"
                          >
                            <td class="py-0">
                              <confirm-negate
                                v-if="!fw.execute"
                                :name="fw.name_fw"
                                @proceed="confirmAlertNegate($event, fw, auditor)"
                              />
                              <span
                                v-if="fw.execute"
                              >
                                {{fw.name_fw}}
                              </span>
                              <v-icon
                                v-if="fw.execute" color="primary" class="va-middle ml-2"
                              >
                                fas fa-spinner fa-spin
                              </v-icon>
                            </td>

                            <td class="py-0 text-right">
                              {{ fw.count_not_confirmed }}
                            </td>
                          </tr>
                        </template>

                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </transition>
        </v-card>
        <v-card-text
          v-if="loading"
          :class="[key === 3 ? 'loading-last' : 'loading']"
        >
          <v-icon color="primary" small>fas fa-spinner fa-spin</v-icon>
          <span class="font-roboto-condensed-light font-size-12 ml-2"
          >Loading...</span
          >
        </v-card-text>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FirewallFilter from "@/components/FirewallFilter";
import ConfirmNegate from "@/views/monitor/auditor/ruleset/components/confirmNegate.vue";
import { confirmRulesetNegateAlerts } from "@/api/monitor/auditor";
import { Errors } from "@/utils/error";

export default {
  components: {
    ConfirmNegate,
    FirewallFilter,
  },
  props: {
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
  },
  data() {
    return {
      location: "",
      expanded: [[], [], [], []],
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
    rulesetLowAlertsCount: {
      get() {
        return this.$store.state.synapsa.rulesetLowAlertsCount;
      },
    },
    rulesetMediumAlertsCount: {
      get() {
        return this.$store.state.synapsa.rulesetMediumAlertsCount;
      },
    },
    rulesetHighAlertsCount: {
      get() {
        return this.$store.state.synapsa.rulesetHighAlertsCount;
      },
    },
    rulesetCriticalAlertsCount: {
      get() {
        return this.$store.state.synapsa.rulesetCriticalAlertsCount;
      },
    },
    auditorRulesetTaskProcessing: {
      get() {
        return this.$store.state.synapsa.auditorRulesetTaskProcessing;
      },
    },
    monitorAuditorRulesetFirewalls: {
      get() {
        return this.$store.state.synapsa.monitorAuditorRulesetFirewalls;
      },
    },
  },
  created() {
    this.location = window.location.origin;
  },
  methods: {
    isOpen(severityIndex, auditorIndex) {
      let isOpen = false;
      this.expanded[severityIndex].forEach(function (value) {
        if (value === auditorIndex) {
          isOpen = true;
        }
      });
      return isOpen;
    },
    openForm(auditor, firewall) {
      this.keywordAddForm = "rulesetalert";
      this.idAddForm = {
        auditor: auditor,
        firewall: firewall,
      };
    },
    confirmAlertNegate(eventData, fw, auditor) {
      this.$set(fw, 'execute', true);
      const data = {
        firewall_id: fw.id_fw,
        firewall_name: fw.name_fw,
        auditor_id: auditor.id,
        auditor_name: auditor.name,
        comment: eventData.comment,
      };

      confirmRulesetNegateAlerts(data)
        .then(() => {
          this.$toast.open({
            message: "Alert was successfully confirmed!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.$set(fw, 'execute', false);
          this.$emit("onConfirmNegate", true);
        })
        .catch((e) => {
          Errors.show(e);
          this.$set(fw, 'execute', false);
        });
    },
    severityClass(status) {
      if (status === 1 || status === "1") {
        return "signal-icon--low";
      } else if (status === 2 || status === "2") {
        return "signal-icon--medium";
      } else if (status === 3 || status === "3") {
        return "signal-icon--high";
      } else {
        return "signal-icon--critical";
      }
    },
    onFirewallsChange(firewalls) {
      this.$emit("onFirewallsChange", firewalls);
    },
    treeLikeArrow(id) {
      if (id === 1 || id === "1") {
        return "severity__low";
      } else if (id === 2 || id === "2") {
        return "severity__medium";
      } else if (id === 3 || id === "3") {
        return "severity__high";
      } else {
        return "severity__critical";
      }
    },
    borderRadiusFix(dataAuditor) {
      if (dataAuditor.auditors.length === 0) {
        return "synapsa-column__border-fix";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.alert-count {
  display: inline;
  background: #e53355;
  color: #fff;
  padding: 0 5px;
  border-radius: 4px;
}
.theme--dark.alert-right {
  color: #fff;
}
.theme--light.alert-right {
  color: black;
}
.alert-right {
  float: right;
  margin-right: 0.25rem;
  padding: 0 5px;
}
.synapsa-column__border-fix {
  border-radius: 15px 15px 0 0 !important;
}

.synapsa-column.theme--dark {
  .v-card__title {
    border-bottom: 2px solid #fff;
  }
  .v-expansion-panel-content {
    background-color: #66666999;
    border-radius: 0 !important;
  }
}
.synapsa-column.theme--light {
  .v-card__title {
    border-bottom: 2px solid #3d3d3f;
  }
  .v-expansion-panel-content {
    background-color: #f7f7f7;
  }
}
.severity__low {
  position: relative;
  .v-expansion-panel--active.odd::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateX(8px);
    top: 0;
    width: 2px;
    height: 100%;
    background: #64c52a;
    z-index: 10;
  }
  .v-expansion-panel--active.odd:last-of-type::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateX(8px);
    top: 0;
    width: 2px;
    height: 84%;
    background: #64c52a;
    z-index: 10;
  }
  .v-expansion-panel--active.odd {
    tr::before {
      content: "";
      position: absolute;
      width: 12px;
      left: 8px;
      height: 1.5px;
      background: #64c52a;
      transform: translateY(12px);
      transition: all 1s ease;
    }
    tr:after {
      content: "";
      position: absolute;
      width: 2.5px;
      left: 8px;
      height: 12px;
      background-color: #64c52a;
      transition: all 1s ease;
    }
    td:first-child::before {
      content: "";
      margin-right: 4px;
      margin-left: -4px;
      display: inline-block !important;
      width: 0;
      height: 0;
      border-left: 12px solid #64c52a;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      vertical-align: middle;
      transition: all 1s ease;
    }
  }
  .v-expansion-panel--active.even::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateX(8px);
    top: 0;
    width: 2px;
    height: 100%;
    background: #4e9a1e;
    z-index: 10;
  }
  .v-expansion-panel--active.even:last-of-type::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateX(8px);
    top: 0;
    width: 2px;
    height: 84%;
    background: #4e9a1e;
    z-index: 10;
  }
  .v-expansion-panel--active.even {
    tr:before {
      content: "";
      position: absolute;
      width: 12px;
      left: 8px;
      height: 1.5px;
      background: #4e9a1e;
      transform: translateY(12px);
      transition: all 1s ease;
    }
    tr:after {
      content: "";
      position: absolute;
      width: 2.5px;
      left: 8px;
      height: 12px;
      background-color: #4e9a1e;
      transition: all 1s ease;
    }
    td:first-child::before {
      content: "";
      margin-right: 4px;
      margin-left: -4px;
      display: inline-block !important;
      width: 0;
      height: 0;
      border-left: 12px solid #4e9a1e;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      vertical-align: middle;
      transition: all 1s ease;
    }
  }
}
.severity__medium {
  position: relative;
  .v-expansion-panel--active.odd::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateX(8px);
    top: 0;
    width: 2px;
    height: 100%;
    background: #ffd04e;
    z-index: 10;
  }
  .v-expansion-panel--active.odd:last-of-type::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateX(8px);
    top: 0;
    width: 2px;
    height: 84%;
    background: #ffd04e;
    z-index: 10;
  }
  .v-expansion-panel--active.odd {
    tr:before {
      content: "";
      position: absolute;
      width: 12px;
      left: 8px;
      height: 1.5px;
      background: #ffd04e;
      transform: translateY(12px);
      transition: all 1s ease;
    }
    tr:after {
      content: "";
      position: absolute;
      width: 2.5px;
      left: 8px;
      height: 12px;
      background-color: #ffd04e;
      transition: all 1s ease;
    }
    td:first-child::before {
      content: "";
      margin-right: 4px;
      margin-left: -4px;
      display: inline-block !important;
      width: 0;
      height: 0;
      border-left: 12px solid #ffd04e;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      vertical-align: middle;
      transition: all 1s ease;
    }
  }
  .v-expansion-panel--active.even::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateX(8px);
    top: 0;
    width: 2px;
    height: 100%;
    background: #cca23c;
    z-index: 10;
  }
  .v-expansion-panel--active.even:last-of-type::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateX(8px);
    top: 0;
    width: 2px;
    height: 84%;
    background: #cca23c;
    z-index: 10;
  }
  .v-expansion-panel--active.even {
    tr:before {
      content: "";
      position: absolute;
      width: 12px;
      left: 8px;
      height: 1.5px;
      background: #cca23c;
      transform: translateY(12px);
      transition: all 1s ease;
    }
    tr:after {
      content: "";
      position: absolute;
      width: 2.5px;
      left: 8px;
      height: 12px;
      background-color: #cca23c;
      transition: all 1s ease;
    }
    td:first-child::before {
      content: "";
      margin-right: 4px;
      margin-left: -4px;
      display: inline-block !important;
      width: 0;
      height: 0;
      border-left: 12px solid #cca23c;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      vertical-align: middle;
      transition: all 1s ease;
    }
  }
}
.severity__high {
  position: relative;
  .v-expansion-panel--active.odd::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateX(8px);
    top: 0;
    width: 2px;
    height: 100%;
    background: #fd981a;
    z-index: 10;
  }
  .v-expansion-panel--active.odd:last-of-type::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateX(8px);
    top: 0;
    width: 2px;
    height: 84%;
    background: #fd981a;
    z-index: 10;
  }
  .v-expansion-panel--active.odd {
    tr:before {
      content: "";
      position: absolute;
      width: 12px;
      left: 8px;
      height: 1.5px;
      background: #fd981a;
      transform: translateY(12px);
      transition: all 1s ease;
    }
    tr:after {
      content: "";
      position: absolute;
      width: 2.5px;
      left: 8px;
      height: 12px;
      background-color: #fd981a;
      transition: all 1s ease;
    }
    td:first-child::before {
      content: "";
      margin-right: 4px;
      margin-left: -4px;
      display: inline-block !important;
      width: 0;
      height: 0;
      border-left: 12px solid #fd981a;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      vertical-align: middle;
      transition: all 1s ease;
    }
  }
  .v-expansion-panel--active.even::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateX(8px);
    top: 0;
    width: 2px;
    height: 100%;
    background: #b16a12;
    z-index: 10;
  }
  .v-expansion-panel--active.even:last-of-type::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateX(8px);
    top: 0;
    width: 2px;
    height: 84%;
    background: #b16a12;
    z-index: 10;
  }
  .v-expansion-panel--active.even {
    tr:before {
      content: "";
      position: absolute;
      width: 12px;
      left: 8px;
      height: 1.5px;
      background: #b16a12;
      transform: translateY(12px);
      transition: all 1s ease;
    }
    tr:after {
      content: "";
      position: absolute;
      width: 2.5px;
      left: 8px;
      height: 12px;
      background-color: #b16a12;
      transition: all 1s ease;
    }
    td:first-child::before {
      content: "";
      margin-right: 4px;
      margin-left: -4px;
      display: inline-block !important;
      width: 0;
      height: 0;
      border-left: 12px solid #b16a12;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      vertical-align: middle;
      transition: all 1s ease;
    }
  }
}
.severity__critical {
  position: relative;
  .v-expansion-panel--active.odd::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateX(8px);
    top: 0;
    width: 2px;
    height: 100%;
    background: #e53355;
    z-index: 10;
  }
  .v-expansion-panel--active.odd:last-of-type::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateX(8px);
    top: 0;
    width: 2px;
    height: 84%;
    background: #e53355;
    z-index: 10;
  }
  .v-expansion-panel--active.odd {
    tr:before {
      content: "";
      position: absolute;
      width: 12px;
      left: 8px;
      height: 1.5px;
      background: #e53355;
      transform: translateY(12px);
      transition: all 1s ease;
    }
    tr:after {
      content: "";
      position: absolute;
      width: 2.5px;
      left: 8px;
      height: 12px;
      background-color: #e53355;
      transition: all 1s ease;
    }
    td:first-child::before {
      content: "";
      margin-right: 4px;
      margin-left: -4px;
      display: inline-block !important;
      width: 0;
      height: 0;
      border-left: 12px solid #e53355;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      vertical-align: middle;
      transition: all 1s ease;
    }
  }
  .v-expansion-panel--active.even::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateX(8px);
    top: 0;
    width: 2px;
    height: 100%;
    background: #b22641;
    z-index: 10;
  }
  .v-expansion-panel--active.even:last-of-type::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateX(8px);
    top: 0;
    width: 2px;
    height: 84%;
    background: #b22641;
    z-index: 10;
  }
  .v-expansion-panel--active.even {
    tr:before {
      content: "";
      position: absolute;
      width: 12px;
      left: 8px;
      height: 1.5px;
      background: #b22641;
      transform: translateY(12px);
      transition: all 1s ease;
    }
    tr:after {
      content: "";
      position: absolute;
      width: 2.5px;
      left: 8px;
      height: 12px;
      background-color: #b22641;
      transition: all 1s ease;
    }
    td:first-child::before {
      content: "";
      margin-right: 4px;
      margin-left: -4px;
      display: inline-block !important;
      width: 0;
      height: 0;
      border-left: 12px solid #b22641;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      vertical-align: middle;
      transition: all 1s ease;
    }
  }
}
.v-expansion-panel {
  border-radius: 0px !important;
}
.v-expansion-panel:last-of-type {
  border-radius: 0 0 14px 14px !important;
  .v-expansion-panel-content {
    border-radius: 0 0 14px 14px !important;
  }
}
.theme--dark .loading {
  background-color: #1e1e1e96 !important;
  max-width: 93.9% !important;
  border-radius: 0 0 15px 15px !important;
}

.theme--dark .loading-last {
  background-color: #1e1e1e96 !important;
  max-width: 100% !important;
  border-radius: 0 0 15px 15px !important;
}

.theme--light .loading {
  background-color: #ffffff6e !important;
  box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
  max-width: 93.9% !important;
  border-radius: 0 0 15px 15px !important;
}
.theme--light .loading-last {
  background-color: #ffffff6e !important;
  box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
  max-width: 100% !important;
  border-radius: 0 0 15px 15px !important;
}
</style>
