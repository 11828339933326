<template>
  <v-container fluid>
    <alert-columns
      :data="data"
      :loading="loading"
      @onFirewallsChange="onFirewallsChange($event)"
      @onConfirmNegate="onConfirmNegate($event)"
    />
  </v-container>
</template>

<script>
import alertColumns from "./components/columns";
import { Errors } from "@/utils/error";
import { getActiveRulesetAlerts } from "@/api/monitor/auditor";

export default {
  components: {
    alertColumns,
  },
  data() {
    return {
      loading: false,
      data: [
        { id: 1, name: "Low", color: "neutral-3", auditors: [] },
        {
          id: 2,
          name: "Medium",
          color: "good-3",
          auditors: [],
        },
        { id: 3, name: "High", color: "medium-3", auditors: [] },
        {
          id: 4,
          name: "Critical",
          color: "bad-3",
          auditors: [],
        },
      ],
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
    auditorRulesetTaskProcessing: {
      get() {
        return this.$store.state.synapsa.auditorRulesetTaskProcessing;
      },
    },
    monitorAuditorRulesetLoadBatch: {
      get() {
        return this.$store.state.synapsa.monitorAuditorRulesetLoadBatch;
      },
    },
    monitorAuditorRulesetFirewalls: {
      get() {
        return this.$store.state.synapsa.monitorAuditorRulesetFirewalls;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeMonitorFirewalls", {
          key: "monitorAuditorRulesetFirewalls",
          value: val,
        });
      },
    },
  },
  watch: {
    monitorAuditorRulesetLoadBatch: {
      immediate: true,
      deep: true,
      handler() {
        this.loadData();
      },
    },
    changeInAddForm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue === "auditorAlert") {
          this.loadData();
        }
        this.changeInAddForm = "";
      },
    },
    auditorRulesetTaskProcessing: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!newValue && !this.loading) {
          this.loadData();
        }
      },
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      const data = {
        firewalls: this.monitorAuditorRulesetFirewalls,
        batch: this.monitorAuditorRulesetLoadBatch,
      };
      getActiveRulesetAlerts(data)
        .then((response) => {
          this.data.map((severity) => (severity.auditors = []));
          response.map((auditor) =>
            this.data[auditor.severity - 1].auditors.push(auditor)
          );
          this.loading = false;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    onFirewallsChange(firewalls) {
      this.monitorAuditorRulesetFirewalls = firewalls;
      this.loadData();
    },
    onConfirmNegate() {
      this.loadData();
    },
  },
};
</script>
