<template>
  <div class="d-flex mb-6 synapsa-box">
    <v-autocomplete
      v-model="selectedFirewalls"
      :items="firewalls"
      :loading="isLoading"
      prepend-icon="mdi-magnify"
      class="search-firewall"
      item-value="id"
      item-text="name"
      clearable
      multiple
      label="Select firewalls"
      :disabled="!firewalls.length"
      @change="onSelectedFirewallsChange"
    >
      <template v-slot:selection="data">
        <v-chip small color="primary" class="elevation-2 synapsa-chip">
          {{ data.item.name }}
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { getFirewallListByVendor } from "@/api/settings/firewall";
import { Errors } from "@/utils/error";

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    width: {
      type: String,
      default: function () {
        return "70";
      },
    },
    timeout: {
      type: Number,
      default: function () {
        return 500;
      },
    },
    selectedIds: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    synapsaSearchInput() {
      return {
        width: `${this.width}%`,
      };
    },
  },
  watch: {
    selectedIds: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.selectedFirewalls = newValue;
      },
    },
  },
  data() {
    return {
      vendor: 1,
      selectedFirewalls: [],
      firewalls: [],
      timeoutId: null,
    };
  },
  created() {
    this.loadFirewalls();
  },
  methods: {
    loadFirewalls() {
      if (this.vendor) {
        getFirewallListByVendor(this.vendor)
          .then((response) => {
            console.log(response);
            this.firewalls = response.data;
          })
          .catch((e) => {
            Errors.show(e);
          });
      }
    },
    onSelectedFirewallsChange() {
      clearTimeout(this.timeoutId);

      this.timeoutId = setTimeout(() => {
        this.$emit("onFirewallsChange", this.selectedFirewalls);
      }, this.timeout);
    },
  },
};
</script>

<style lang="scss" scoped>
$synapsaGreen: #69be28;
.synapsa-box {
  margin: 0;
  padding: 0 0.5em;
  .theme--dark.v-btn:hover,
  .theme--light.v-btn:hover {
    color: $synapsaGreen;
  }
  .v-btn {
    background-color: transparent;
    min-width: 36px;
  }
}
.v-application.theme--light {
  .synapsa-box {
    background-color: #ffffff6e !important;
    box-shadow: 13px 13px 30px rgb(0 0 0 / 10%);
    border-radius: 15px;
  }
  .backup-btn {
    background-color: transparent !important;
    background: transparent !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .backup-btn:hover {
    color: #69be28 !important;
  }
}
.v-application.theme--dark {
  .synapsa-box {
    background-color: #1e1e1e96 !important;
    box-shadow: 13px 13px 30px rgb(0 0 0 / 30%);
    border-radius: 15px;
    .backup-btn {
      background-color: transparent !important;
      background: hsla(0, 0%, 100%, 0.08) !important;
    }
  }
}

::v-deep .v-input__slot {
  background: none !important;
  box-shadow: none !important;
}
</style>
